import { createRouter, createWebHashHistory } from 'vue-router';
import App from '../App.vue';

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'Inicio',
                component: () => import('../pages/Inicio.vue')
            },
        ]
    },

];




const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
