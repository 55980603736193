<template>
	<div>
        <Dialog :header="$t('Ingresar')" v-model:visible="$store.state.view.login" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true" style="background: #727376;position: absolute;">
            <div class="p-fluid">
                <div class="grid p-fluid mt-3">
                    <div class="field col-12 md:col-12">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText id="inputgroup" type="email" v-model="correo" :disabled="recuperar==true"/>
                                <label for="inputgroup">{{$t('Correo')}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="field col-12 md:col-12" v-if="!recuperar">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText id="inputgroup" type="password" v-model="clave" @keyup.enter="Login"/>
                                <label for="inputgroup">{{$t('Contraseña')}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="field col-12 md:col-12" v-if="!recuperar">
                            <div class="flex align-items-center">
                                <Checkbox id="rememberme1" v-model="guardar" :binary="true" class="mr-2"></Checkbox>
                                <label for="rememberme1">Recuérdame</label>
                            </div>
                        </div>

                    <div class="field col-12 md:col-12" v-if="recuperar">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-unlock"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText id="inputgroup" type="text" v-model="codigo" @input="ValidarCodigo" :disabled="validar == true"/>
                                <label for="inputgroup">{{$t('Código Validación')}}</label>
                            </span>
                        </div>
                        <div v-if="!validar">{{$t('Código Validación Mens')}}</div>
                    </div>
                    <div class=" col-12 md:col-6" v-if="validar">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-unlock"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText id="inputgroup" type="password" v-model="clave"/>
                                <label for="inputgroup">{{$t('Contraseña')}}</label>
                            </span>
                        </div>
                    </div>
                    <div class=" col-12 md:col-6" v-if="validar">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-unlock"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText id="inputgroup" type="password" v-model="claveconf"  @input="ValidarClave"/>
                                <label for="inputgroup">{{$t('Confirmar Contraseña')}}</label>
                            </span>
                        </div>
                        <div v-if="!validarclave">{{$t('Error en Confirmación')}}</div>                     
                    </div>
                </div>	
            </div>	
            <template #footer>
                <Button :label="$t('Aceptar')" icon="pi pi-check" class="p-button-primary mr-2 mb-2" @click="Ingresar" v-if="!recuperar"/>
                <Button :label="$t('Aceptar')" icon="pi pi-check" class="p-button-secondary mr-2 mb-2" @click="Reset" v-else/>
                <Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger mr-2 mb-2" @click="cerrar" v-if="this.$store.state.config.landingpage"/>
                <Button :label="$t('Recuperar')" icon="pi pi-key" class="p-button-warning mr-2 mb-2" @click="openRecovery" v-if="!recuperar"/>
            </template>
        </Dialog>
	</div>
</template>

<script>
import API from "../service/API";
import Crypto from "crypto-js";

export default {
    data() {
        return {
            correo: null,
            clave: null,
            claveconf: null,
            validar: false,
            codigo: null,
            codigosend: null,
            size: '30vw',
            visibleFull: false,
            recuperar: false,
            validarclave: false,
            checked: false,
            guardar: false,
		}
	},
    created() { 
        if (this.$store.state.mobile){this.size = '80vw';}
        if (localStorage.getItem('email')){
            this.correo = localStorage.getItem('email');  
        }
        if (localStorage.getItem('correo') || localStorage.getItem('clave')){
            this.correo = localStorage.getItem('correo');  
            this.clave = localStorage.getItem('clave');  
            this.guardar = true;
        }

    },
    methods: {
        cerrar() {
            this.clave = null;
            this.claveconf = null;
            this.validar = false;
            this.codigo = null;
            this.codigosend = null;
            this.recuperar = false;
            this.validarclave = false;
            this.$store.state.view.login = false;
        },
        openRecovery() {
            if (this.correo == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Introduzca correo'), life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.correo)) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Correo'), life: 10000});
            } else {
                if (this.guardar){
                    localStorage.setItem('correo', this.correo);
                    localStorage.setItem('clave', this.clave);
                } else {
                    localStorage.removeItem('correo');
                    localStorage.removeItem('clave');
                }
                const Consulta = new API('Seguridad','Reset');
                Consulta.Procesar(null,{
                    correo: this.correo
                }).then(response => {
                    //this.$store.state.error = response;
                    if(response.correo){
                        this.codigosend = response.codigo;
                        this.$toast.add({severity:'success', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                        this.recuperar = true;
                    } else {
                        this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                    } 
                    this.$store.commit('Loading');                  
                });         
            }     
        },
        ValidarCodigo() {
            if (this.codigo == this.codigosend) {
                this.clave = null;
                this.claveconf = null;
                this.validar = true;
            } else { this.validar = false; }
        },
        ValidarClave() {
            if (this.clave == this.claveconf) {
                this.validarclave = true;
            } else { this.validarclave = false; }
        },
        Ingresar() { 
            if (this.correo == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Introduzca correo'), life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.correo)) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Correo'), life: 10000});
            } else if (this.clave == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Introduzca contraseña'), life: 10000});
            } else {
                if (this.guardar){
                    localStorage.setItem('correo', this.correo);
                    localStorage.setItem('clave', this.clave);
                } else {
                    localStorage.removeItem('correo');
                    localStorage.removeItem('clave');
                }
                const cifrado = Crypto.HmacSHA1(this.clave, "btcoin").toString();
                this.$store.state.clave = cifrado;
                const Consulta = new API('Seguridad','Login');         
                Consulta.Procesar(null,{
                    correo: this.correo,
                    clave:  cifrado
                }).then(response => {
                    //this.$store.state.error = response;
                    this.$store.commit('Loading');
                    if(response.authenticated){
                        this.$toast.add({severity:'success', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                        this.$store.commit('Login',response);
                        this.$store.commit('Menu');
                        localStorage.setItem('email', this.correo);
                        this.cerrar();
                    } else {
                        this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});        
                    }
                });
                
            }
        },
        Reset() {
            if (this.clave == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Contraseña'), life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.correo)) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Correo'), life: 10000});
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_.!@#$%^&*])(?=.{8,})/.test(this.clave)) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Contraseña'), life: 10000});
            } else if (this.clave != this.claveconf) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Confirmación'), life: 10000});
            } else {
                this.$store.commit('Loading');
                const cifrado = Crypto.HmacSHA1(this.clave, "btcoin").toString();
                const Consulta = new API('Seguridad','ResetClave');
                Consulta.Procesar(null,{
                    correo: this.correo,
                    codigo: this.codigo,
                    clave: cifrado
                }).then(response => {
                    this.$store.state.error = response;	
                    if(response.error){
                        this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                    } else {
                        this.$store.commit('Loading'); 
                        this.Ingresar();
                    }
                });
            } 
        },
	},
    watch: {
	},


}
</script>
