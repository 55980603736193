import axios from "axios";

let URL = {
  api: "./api/",
  prueba: "https://ruledca.com/api/",
  red: "http://192.168.1.2/vue-project/Sistema/src/api/",
  local: "http://localhost/vue-project/Sistema/src/api/",
};

export default parametros => {
  const http = axios.post(URL.api, parametros);
  return http;
};
