<template>
	<div>
        <Dialog style="background: #727376;" :header="$t('Registrarse')" v-model:visible="$store.state.view.register" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true">
            <div class="p-fluid">
                <strong>{{$t('MensRegistro1')}}</strong>
                <div class="p-text-center"><strong v-if="!verificacion">{{$t('MensRegistro2')}}</strong></div>
                
                <div class="grid p-fluid mt-3">
                    <div class="field col-12 md:col-12">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText id="inputgroup" type="email" v-model="correo" :disabled="proceso == true || verificacion == true" />
                                <label for="inputgroup">{{$t('Correo')}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="field col-12 md:col-12" v-if="proceso">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-unlock"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText id="inputgroup" type="text" v-model="codigo" @input="ValidarCodigo" :disabled="verificacion == true"/>
                                <label for="inputgroup">{{$t('Código Validación')}}</label>
                            </span>
                        </div>
                        <div v-if="!validar">{{$t('Código Validación Mens')}}</div>
                    </div>
                    <div class="field col-12 md:col-12" v-if="verificacion">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText id="inputgroup" type="text" v-model="nombre"/>
                                <label for="inputgroup">{{$t('Nombre Completo')}}</label>
                            </span>
                        </div>
                    </div>
                    <div class=" col-12 md:col-6" v-if="verificacion">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-unlock"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText id="inputgroup" type="password" v-model="clave"/>
                                <label for="inputgroup">{{$t('Contraseña')}}</label>
                            </span>
                        </div>
                        <div>{{$t('Contraseña Format')}}</div> 
                    </div>
                    <div class=" col-12 md:col-6" v-if="verificacion">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-unlock"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText id="inputgroup" type="password" v-model="claveconf"  @input="ValidarClave"/>
                                <label for="inputgroup">{{$t('Confirmar Contraseña')}}</label>
                            </span>
                        </div>
                        <div v-if="!validarclave">{{$t('Error en Confirmación')}}</div>                     
                    </div>
                </div>	
			</div>	
            <template #footer>
                <Button :label="$t('Aceptar')" icon="pi pi-check" class="p-button-secondary" @click="Register" autofocus  v-if="verificacion"/>
                <Button :label="$t('Validar Correo')" icon="pi pi-check" class="p-button-secondary" @click="Validacion" autofocus  v-else/>
                <Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="cerrar"/>
            </template>
        </Dialog>
	</div>
</template>

<script>
import API from "../service/API";
import Crypto from "crypto-js";

export default {
    props: {
		display: {
			type: Boolean,
			default: false,
		}
	},
    data() {
        return {
            displayRegister: false,
            correo: null,
            clave: null,
			nombre: null,
            claveconf: null,
            size: '40vw',
            proceso: false,
            verificacion: false,
            codigo: null,
            verificacioncodigo: null,
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '80vw';}
    },
    methods: {
        cerrar() {
            this.correo = null;
            this.nombre = null;
            this.clave = null;
            this.claveconf = null;
            this.verificacion = false;
            this.verificacioncodigo = null;
            this.codigo = null;
            this.proceso = false;
            this.displayRegister = false;
            this.$store.state.view.register = false;
        },
        ValidarCodigo() {
            if (this.codigo == this.verificacioncodigo) {
                this.clave = null;
                this.claveconf = null;
                this.verificacion = true;
            } else { this.verificacion = false; }
        },
        ValidarClave() {
            if (this.clave == this.claveconf) {
                this.validarclave = true;
            } else { this.validarclave = false; }
        },
        Validacion() {
            if (this.correo == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Correo'), life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.correo)) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Correo'), life: 10000});
            } else {
                this.$store.commit('Loading');
                const Consulta = new API('Seguridad','EnviarCodigo'); 
                Consulta.Procesar(null,{
                    correo: this.correo
                }).then(response => {
                    //this.$store.state.error = response;
                    if(response.codigo){
                        this.verificacioncodigo = response.codigo;
                        this.proceso = true;
                        this.$toast.add({severity:'success', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                    } else {
                        this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                    } 
                    this.$store.commit('Loading');                  
                });         
            } 
        },
        Register() {
            if (this.nombre == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Nombre'), life: 10000});
            } else if (this.clave == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Contraseña'), life: 10000});
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_.!@#$%^&*])(?=.{8,})/.test(this.clave)) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Contraseña'), life: 10000});
            } else if (this.clave != this.claveconf) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Confirmación'), life: 10000});
            } else if (this.codigo != this.verificacioncodigo) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Código'), life: 10000});
            } else {

                this.$store.commit('Loading');
                const cifrado = Crypto.HmacSHA1(this.clave, "btcoin").toString();
                const Consulta = new API('Seguridad','Registrar');
                Consulta.Procesar(null,{
                    nombre: this.nombre,
                    correo: this.correo,
                    clave: cifrado
                }).then(response => {
                    //this.$store.state.error = response;	
                    if(response.error){
                        this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                        this.$store.commit('Loading');
                        
                    } else {
                        this.$toast.add({severity:'success', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                        this.$store.commit('Loading');
                        this.$store.state.view.login = false;
                        this.$store.state.view.register = false;
                        this.$store.commit('Login',response);
                        this.$store.commit('Menu');
                        localStorage.setItem('email', this.correo);
                    }                
                });
                this.limpiar();
            } 
        },
	},
    watch: {
		display(newValue) {
			this.displayRegister = newValue;
		}
	},


}
</script>