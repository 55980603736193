<template>
	<div class="layout-footer" style="background: #000000;">
		
        <div class="text-orange-400 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap">
            <div class="font-bold mr-8">
				🔥 Rule DCA 🔥
				<span class="font-medium ml-2" style="right: 5px">
					<i class="pi pi-check-circle" style="fontSize: 1rem" v-if="this.$store.state.estatus"></i>
					<i class="pi pi-exclamation-circle" style="fontSize: 1rem" v-else></i>
				</span>
				<span class="font-medium ml-2" style="right: 5px">
					<i class="pi pi-mobile" style="fontSize: 1rem" v-if="this.$store.state.mobile"></i>
					<i class="pi pi-desktop" style="fontSize: 1rem" v-else></i>
				</span>


			</div>
            <div class="align-items-center ">
                <span class="line-height-3">Keep It Simple, Stupid!. Hazlo Simple, Estúpido!</span>
            </div>
            <a class="flex align-items-center ml-2 mr-8">
            <a class="text-orange-500" href="https://t.me/+4EtRDtnliiZkYWEx" target="_blank"><span>🔥 Enlace a Telegram. Comienza YA 🔥</span></a>
            </a>
		</div>

	</div>
</template>

<script>
	export default {
		name: "AppFooter",
		methods: {
			footerImage() {
				return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
			}
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>