<template>
	<div class="layout-topbar" style="background: #000000;">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			<span style="color: #F6911D;"><strong>Rule DCA</strong></span>
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="$store.commit('MenuActive')" v-if="this.$store.state.authenticated">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>

        <ul class="layout-topbar-menu hidden lg:flex origin-top">
            <Button label="Home" class="p-button-secondary p-button-text" style="color: #F6911D;" @click="Navegacion('inicio')"/>
            <Button label="Oferta de Cursos" class="p-button-secondary p-button-text" style="color: #F6911D;" @click="Navegacion('oferta')" />
            <Button label="Asesorias" class="p-button-secondary p-button-text" style="color: #F6911D;" @click="Navegacion('asesoria')" />
            <Button label="Precios" class="p-button-secondary p-button-text" style="color: #F6911D;" @click="Navegacion('cursos')" />
			<!--
			<li v-if="!this.$store.state.authenticated && this.$store.state.config.login">
				<button class="p-link layout-topbar-button" @click="$store.state.view.login=true">
					<i class="pi pi-sign-in"></i>
					<span>{{$t('Ingresar')}}</span>
				</button>
			</li>
			<li v-if="!this.$store.state.authenticated  && this.$store.state.config.register">
				<button class="p-link layout-topbar-button" @click="$store.state.view.register=true">
					<i class="pi pi-user-plus"></i>
					<span>{{$t('Registrarse')}}</span>
				</button>
			</li>
			<li v-if="this.$store.state.authenticated && this.$store.state.config.login">
				<button class="p-link layout-topbar-button" @click="$store.commit('Logout')">
					<i class="pi pi-sign-out"></i>
					<span>{{$t('Salir')}}</span>
				</button>
			</li>
			
			<li>
				<button class="p-link layout-topbar-button" @click="lenguajeChange">
					<img :src="'img/' + i18n.locale() + '.png'" style="height:20px;margin-left:5px;margin-right:5px"/>
				</button>
			</li>
			-->
			
		</ul>

		<Login/>
		<Register/>

	</div>
</template>

<script>
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import { useI18nPlugin } from '@unify/vuex-i18n';

export default {
	components: {
        'Login': Login,
        'Register': Register
    },
    data() {
        return {
			displayLogin: false,
            displayRegister: false,
            i18n: null,
			english: false,
			menutop: []
		}
	},
    created() {  
        this.i18n = useI18nPlugin();
		if (localStorage.getItem('leng')){
            this.i18n.set(localStorage.getItem('leng'));  
        }
		/*    
        if (this.$store.state.mobile){this.size = '80vw';}
        this.i18n = useI18nPlugin();
		if (localStorage.getItem('leng')){
            this.i18n.set(localStorage.getItem('leng'));  
        }
		this.$store.commit('Menu');
		*/
    },
    methods: {
        lenguajeChange() {
            if(this.i18n.locale() == 'en') {
                this.i18n.set('es');
                localStorage.setItem('leng','es');
            } else {
                this.i18n.set('en');
                localStorage.setItem('leng','en');
            }
        },
        Navegacion(id){
            this.$store.state.view.landing = id;
            this.$emit('menu-toggle', event);
        }, 
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return 'logo.png';
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>