import { createStore } from 'vuex'
import API from "../service/API";
import router from '../routes';
import { useI18nPlugin } from '@unify/vuex-i18n';

export default createStore({
  state: {
    estatus: false,
    error: null,
    mobile: false,
    loading: false,
    landingpage: 'blank',
    pago: false,
    config: {
      landingpage: false,
      login: false,
      register: false,
    },
    tasa: 17, 
    clave: null,
    id: 0,
    usuario: 0,
    nombre: null,
    correo: null,
    foto: null,
    authenticated: false,
    MenuActive: true,
    MenuActiveMobile: false,

    insert: false,
    edit: false,
    delete: false,

    view: {
      login: false,
      register: false,
      landing: null,
    },

    fecha: null,
    admin: false,
    menu: {
      es: {
        principal: [],
        admin: [],
        externo: []
      },
      en: {
        principal: [],
        admin: [],
        externo: []
      }
    },

  },
  mutations: {
    Loading(){
      this.state.loading = false;	
    },
    MenuActive(){
      if(this.state.mobile){
        this.state.MenuActiveMobile = !this.state.MenuActiveMobile;
      } else {
        this.state.MenuActive = !this.state.MenuActive;
      }	
    },
    Mobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        || screen.width<1024) {
          this.state.mobile = true;
      } else {
        this.state.mobile = false;
      }    
    },
    Estatus(){
      const Consulta = new API();
      Consulta.Estatus().then(response => {
        //this.state.error = response;
        this.state.fecha = response.fecha;
        this.state.config = response.config;
        this.state.loading = false;
        if(response.config.landingpage){
          this.state.landingpage = response.config.page;
        }
        this.state.estatus = response.estatus;
      });   
    },
    Login(state,valores){
      //this.state.error = valores;
      this.state.authenticated = valores.authenticated;
      localStorage.setItem('Token', valores.token);
      this.state.usuario = valores.id;
      this.state.id = valores.id;
      if(valores.foto){this.state.foto = valores.foto;}
      this.state.nombre = valores.nombre;
      this.state.correo = valores.correo;
      this.state.admin = valores.admin;

      this.state.menu.es.principal = valores.menu.filter(val => val.modulo > 1);
      this.state.menu.es.admin = valores.menuadmin;
      //this.state.menu.es.externo = valores.menuexterno;
      this.state.menu.es.externo = valores.menu.filter(val => val.modulo == 1);

      this.state.MenuActive = false;

    },
    Menu(){
      const i18n = useI18nPlugin();
      this.state.menu.en = {
        principal: [],
        admin: [],
        externo: [],

      };
      i18n.set('en');
      this.state.paginas = [];
      this.state.menu.es.externo.forEach(element => {
          this.state.paginas.push(element.to);
      });  
      this.state.menu.es.principal.forEach(element => {
        this.state.tras= [];
        element.items.forEach(element => {
          this.state.tras.push({
            label: i18n.translate(element.label),
            icon: element.icon,
            to: element.to
          });
          this.state.paginas.push(element.to);
        }); 
        this.state.menu.en.principal.push({
          modulo: element.modulo,
          label: i18n.translate(element.label),
          icon: element.icon,
          items: this.state.tras
        });
      });  

      if (this.state.admin){
        this.state.menu.es.admin.forEach(result => {
          this.state.menu.en.admin.push({
            label: i18n.translate(result.label),
            icon: result.icon,
            to: result.to
          });
          this.state.paginas.push(result.to);
        });
      }  

      this.state.menu.es.externo.forEach(result => {
        this.state.menu.en.externo.push({
          label: i18n.translate(result.label),
          icon: result.icon,
          to: result.to
        });
      });
      
      if (localStorage.getItem('leng')){
        i18n.set(localStorage.getItem('leng'));  
      }

    },
    Tasa(){    
      fetch("https://s3.amazonaws.com/dolartoday/data.json")
        .then(r => r.json())
        .then(response => {
          this.state.tasa = parseFloat(response.USD.transferencia).toFixed(2);
      });
    },
    Logout(){
      const Consulta = new API('Seguridad','Exit');
      Consulta.Ini();
      this.state.loading = false;
      localStorage.removeItem('Token');
      this.state.MenuActive = true;
      this.state.authenticated = false;
      this.state.usuario = null;   
      this.state.correo = null;
      this.state.valido = false;
      this.state.admin = false;
      this.state.paginas = [];
      if(this.state.config.login && !this.state.config.landingpage){
        this.state.view.login = true;
      }
    },
    Validar(state,pagina){
      this.state.insert = false;
      this.state.edit = false;
      this.state.delete = false;
      if(!this.state.paginas.includes(pagina.path)){
        router.push({ path: '/' });
      } 
    },
  },
  actions: {
  },
  modules: {
  }
})
